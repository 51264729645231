@import "definitions.scss";

html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    background: $full_bg;
    color: $base_font_color; 
}

body {
    padding: 0; 
    font-family: $font_family;
    font-size: $font_size;
    margin: 0;
    background-color:#efefef;
}

/*Links*/

a, a:visited, a:active, a:hover, a:focus{
    color: inherit;
}

/*Listen*/

ul{
    padding: 0;
    margin: 0;
    border: none;
    li{
        list-style: none;
    }
}

/*Links*/
a{
    cursor: pointer;
    text-decoration: none;
}

/*Bilder*/
img{
    outline: none;
}

span.clickable {
    cursor: pointer;
}